import React, { useContext, useEffect, useState } from 'react';
import InnerBody from '../../../../content/InnerBody';
import { Col, Row, Table, Button } from 'reactstrap';
import { BuyerContext } from '../../../../apis/buyer';
import { SessionContext } from '../../../../apis/authentication';
import { CustomModal, Countdown } from '../../../../shared/components';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { useDeviceDetect } from '../../../../shared/hooks';
import { RECOMMENDATION_ORDER_CUTOFF_POINT_IN_MIN } from '../../../../shared/constants/constants';
import { commafy } from '../../../../shared/utility/commafyValue';
import { RoundNum } from '../../../../shared/utility/roundingNumber';

function Recommendation() {
  const [order, setOrder] = useState(null);
  const { isMobile } = useDeviceDetect();
  const [currentPurchaseOrder, setCurrentPurchaseOrder] = useState(null);
  const [currentPurchaseOrderIndex, setCurrentPurchaseOrderIndex] = useState(0);
  const buyerContext = useContext(BuyerContext);
  const {
    getOrder,
    submitPurchaseOrder,
    cancelPurchaseOrder,
    getCartProductItems,
    deleteCartProductItems,
    setOrderResponse,
    deletePurchaseOrder,
  } = buyerContext;
  const { orderResponse } = buyerContext.state;
  const [isOrderPopup, setIsOrderPopup] = useState(false);
  const { t } = useTranslation();
  const [isSubmitOrder, setIsSubmitOrder] = useState(false);
  const [isOrderTimeOutModal, setIsOrderTimeOutModal] = useState(false);
  const [cartProductItems, setCartProductItems] = useState([]);
  const navigate = useNavigate();
  const sessionContext = useContext(SessionContext);

  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  });
  useEffect(() => {
    let value = params.orderId;
    if (value != null) {
      getOrder(value);
    }

    getCartProductItems(true).then(res => {
      if (res.err) {
        return;
      } else {
        setCartProductItems(res.res);
      }
    });

    return () => {
      setOrderResponse(null);
    };
  }, []);

  useEffect(() => {
    if (orderResponse?.res && currentPurchaseOrderIndex != 0) {
      setCurrentPurchaseOrder(
        orderResponse.res.purchaseOrders[currentPurchaseOrderIndex],
      );
    }
  }, [currentPurchaseOrderIndex]);

  useEffect(() => {
    if (orderResponse?.res) {
      setOrder(orderResponse.res);
      setCurrentPurchaseOrderIndex(0);
      setCurrentPurchaseOrder(orderResponse.res?.purchaseOrders[0]);
    }
  }, [orderResponse]);

  //to send orders automatically
  useEffect(() => {
    if (isSubmitOrder) {
      const timer = setTimeout(() => {
        setIsOrderPopup(!isOrderPopup);
        if (currentPurchaseOrderIndex + 1 === order.purchaseOrders.length) {
          sessionContext.clearDeliveryDateAndSlot();
          navigate('/buyer/home');
        } else {
          setCurrentPurchaseOrderIndex(prevIndex => prevIndex + 1);
        }
        setIsSubmitOrder(false);
      }, 4000);

      return () => clearTimeout(timer);
    }
  }, [
    isSubmitOrder,
    navigate,
    currentPurchaseOrderIndex,
    order,
    sessionContext,
  ]);

  const deleteFromCart = tempPurchaseOrder => {
    const ids = tempPurchaseOrder.products.map(x => x.productId);
    const item = {
      CartId: cartProductItems.cartId,
      ProductId: ids,
      ExpectedDeliverySlotId: cartProductItems.expectedDeliverySlotId,
      ExpectedDeliveryDate: moment(
        cartProductItems.expectedDeliveryDate,
      ).format('YYYY-MM-DD'),
    };
    deleteCartProductItems(item).then(() => {
      getCartProductItems();
    });
  };

  useEffect(
    () => () => {
      if (params.orderId) deletePurchaseOrder(params.orderId);
    },
    [],
  );

  const onSubmitOrder = () => {
    submitPurchaseOrder(
      order.id,
      currentPurchaseOrder.id,
      currentPurchaseOrder.statusId == 1,
    ).then(() => {
      deleteFromCart(currentPurchaseOrder);
      setIsSubmitOrder(true);
      setIsOrderPopup(prevProps => !prevProps);
    });
  };

  const onCancelOrder = () => {
    cancelPurchaseOrder(order.id, currentPurchaseOrder.id).then(() => {
      deleteFromCart(currentPurchaseOrder);
      if (
        currentPurchaseOrderIndex + 1 <
        orderResponse.res.purchaseOrders.length
      ) {
        setCurrentPurchaseOrderIndex(currentPurchaseOrderIndex + 1);
        setIsOrderPopup(!isOrderPopup);
      } else {
        sessionContext.clearDeliveryDateAndSlot();
        navigate('/buyer/home');
        setIsOrderPopup(!isOrderPopup);
      }
    });
  };

  const orderPopupToggle = () => setIsOrderPopup(!isOrderPopup);
  const orderTimeOutPopupToggle = () => {
    if (isOrderTimeOutModal) {
      sessionContext.clearDeliveryDateAndSlot();
      navigate('/buyer/makeaneworder?step=3');
    }
    setIsOrderTimeOutModal(!isOrderTimeOutModal);
  };

  const orderPopupActionHanlde = isActionProceed => {
    if (isActionProceed) {
      onCancelOrder();
    } else {
      setIsOrderPopup(!isOrderPopup);
    }
  };
  // const orderPopupOK = () => {
  //   setIsOrderPopup(!isOrderPopup);
  //   if (currentPurchaseOrderIndex + 1 === order.purchaseOrders.length) {
  //     sessionContext.clearDeliveryDateAndSlot();
  //     navigate('/buyer/home');
  //   }
  //   setCurrentPurchaseOrderIndex(currentPurchaseOrderIndex + 1);
  // };

  const orderTimeOutPopUp = () => {
    return (
      <CustomModal
        isOpen={isOrderTimeOutModal}
        toggle={orderTimeOutPopupToggle}
        hideCancel={true}
        isScrollable={true}
        enableFooter={false}
        title={t('TIME_OUT')}
        modalstyleClassName="modal-style"
        modalContentContainer="modal-container"
        modalHeaderClassName="modal-header"
        modalheaderTopic="modal-header-topic m-auto"
        modalFooterClassName="modal-footer"
        modalBodyClassName="small"
      >
        <>
          <p className="my-4">
            {moment(currentPurchaseOrder?.orderCutOffPointDateTime).isSame(
              moment.min([
                moment(order?.createdDateTime).add(
                  RECOMMENDATION_ORDER_CUTOFF_POINT_IN_MIN,
                  'minutes',
                ),
                moment(currentPurchaseOrder?.orderCutOffPointDateTime),
              ]),
            )
              ? t(
                  'THE_SELECTED_DELIVERY_SLOT_HAS_REACHED_THE_MAXIMUM_NUMBER_OF_ORDERS_TRY_AGAIN',
                )
              : t(
                  'TIME_HAS_PASSED_THE_PRODUCT_PRICES_INVENTORIES_IN_YOUR_PURCHASE_ORDER__WILL_BE_REFRESHED',
                )}
          </p>
          <Button
            className="form-submit w-40"
            type="submit"
            color="primary"
            onClick={() => {
              // sessionContext.clearDeliveryDateAndSlot();
              navigate('/buyer/makeaneworder?step=3');
              setIsOrderTimeOutModal(!isOrderTimeOutModal);
            }}
          >
            {' '}
            {t('OK')}
          </Button>
        </>
      </CustomModal>
    );
  };
  const orderPopup = () => {
    return (
      <CustomModal
        isOpen={isOrderPopup}
        toggle={orderPopupToggle}
        isScrollable={true}
        enableFooter={false}
        hideCancel={true}
        title={
          isSubmitOrder ? t('ORDER_CONFIRMATION') : t('ORDER_CANCELLATION')
        }
        modalstyleClassName="modal-style"
        modalContentContainer="modal-container"
        modalHeaderClassName="modal-header"
        modalheaderTopic="modal-header-topic m-auto"
        modalFooterClassName="modal-footer"
        modalBodyClassName="small"
      >
        {isSubmitOrder ? (
          <>
            <p className="my-4">
              {t(
                'YOU_HAVE_SUCCESSFULLY_PLACED_THE_ORDER_THANK_YOU_FOR_SHOPPING_WITH_US',
              )}
            </p>
            {/* <Button
              className="form-submit w-40"
              type="submit"
              color="primary"
              onClick={() => {
                orderPopupOK();
                //TODO:SM-145
              }}
            >
              {' '}
              {t('OK')}
            </Button> */}
          </>
        ) : (
          <>
            <p className="my-4">{t('ARE_YOU_WANT_TO_CANCEL_PURCHASE_ORDER')}</p>
            <div className="d-flex justify-content-around mx-5">
              <Button
                outline
                color="primary"
                type="submit"
                className="form-submit"
                style={{ width: '75px' }}
                onClick={() => {
                  orderPopupActionHanlde(true, isSubmitOrder);
                }}
              >
                {t('YES')}
              </Button>
              <Button
                color="primary"
                type="submit"
                className="form-submit"
                style={{ width: '75px' }}
                onClick={() => {
                  orderPopupActionHanlde(false, isSubmitOrder);
                }}
              >
                {t('NO')}
              </Button>
            </div>
          </>
        )}
      </CustomModal>
    );
  };
  useEffect(() => {
    const interval = setInterval(() => {
      if (
        currentPurchaseOrder?.orderCutOffPointDateTime &&
        moment(currentPurchaseOrder?.orderCutOffPointDateTime).isBefore(
          moment(),
        )
      ) {
        setIsOrderTimeOutModal(true);
      }
      if (
        !moment(currentPurchaseOrder?.orderCutOffPointDateTime).isSame(
          moment.min([
            moment(order?.createdDateTime).add(
              RECOMMENDATION_ORDER_CUTOFF_POINT_IN_MIN,
              'minutes',
            ),
            moment(currentPurchaseOrder?.orderCutOffPointDateTime),
          ]),
        )
      ) {
        clearInterval(interval);
      }
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <InnerBody
      topic="Recommendation"
      rightSideElement={
        order?.createdDateTime ? (
          <Countdown
            startTime={moment.min([
              moment().add(5, 'minutes'),
              moment(order?.createdDateTime).add(6, 'minutes'),
              moment(currentPurchaseOrder?.orderCutOffPointDateTime),
            ])}
            onComplete={() => setIsOrderTimeOutModal(true)}
          />
        ) : (
          <></>
        )
      }
    >
      <div className="recommendation-container">
        {orderTimeOutPopUp()}

        {order?.purchaseOrders?.length > 0 && currentPurchaseOrder && (
          <>
            <div style={isMobile ? { textAlign: 'center' } : {}}>
              {t('PURCHASE_ORDER')} {currentPurchaseOrderIndex + 1} {t('OF')}{' '}
              {order.purchaseOrders.length}
            </div>
            {!isMobile ? (
              <div className="d-md-flex">
                {order.expectedDeliveryDate && (
                  <div
                    className="p-2 date-info d-flex flex-wrap me-0 me-md-3"
                    style={{ backgroundColor: '#ffedd7' }}
                  >
                    <div className="ms-2" style={{ color: '#AC7B6F' }}>
                      {t('EXPECTED')} {t('DELIVERY_DATE')}:
                    </div>
                    <div className="mx-2" style={{ color: '#FE7A4F' }}>
                      {' '}
                      {moment(order.expectedDeliveryDate).format(
                        'DD-MM-YYYY',
                      )}{' '}
                      | {order.expectedDeliverySlot.startTime} -{' '}
                      {order.expectedDeliverySlot.endTime}{' '}
                    </div>
                  </div>
                )}
                {currentPurchaseOrder.actualDeliveryDate && (
                  <div
                    className="p-2 date-info mt-3 mt-md-0 d-flex flex-wrap"
                    style={{ backgroundColor: '#FDF2C5' }}
                  >
                    <div className="ms-2" style={{ color: '#918342' }}>
                      {t('ACTUAL')} {t('DELIVERY_DATE')}:
                    </div>
                    <div className="mx-2" style={{ color: '#C4A61F' }}>
                      {' '}
                      {moment(currentPurchaseOrder.actualDeliveryDate).format(
                        'DD-MM-YYYY',
                      )}{' '}
                      |{' '}
                      {
                        currentPurchaseOrder.baseDeliverySlot.deliverySlot
                          .startTime
                      }{' '}
                      -{' '}
                      {
                        currentPurchaseOrder.baseDeliverySlot.deliverySlot
                          .endTime
                      }{' '}
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <>
                <div className="d-md-flex">
                  {order.expectedDeliveryDate && (
                    <div
                      className="p-2 date-info d-flex flex-wrap me-0 me-md-3"
                      style={{ backgroundColor: '#ffedd7', width: '100%' }}
                    >
                      <div
                        style={{
                          color: '#AC7B6F',
                          textAlign: 'center',
                          width: '100%',
                        }}
                      >
                        Delivery Dates
                      </div>
                      <div
                        style={{
                          textAlign: 'center',
                          width: '100%',
                          color: '#AC7B6F',
                        }}
                      >
                        {t('EXPECTED')}:{' '}
                        <span style={{ color: '#FE7A4F' }}>
                          {' '}
                          {moment(order.expectedDeliveryDate).format(
                            'DD-MM-YYYY',
                          )}{' '}
                          | {order.expectedDeliverySlot.startTime} -{' '}
                          {order.expectedDeliverySlot.endTime}{' '}
                        </span>
                      </div>
                      {currentPurchaseOrder.actualDeliveryDate && (
                        <div
                          style={{
                            textAlign: 'center',
                            width: '100%',
                            color: '#918342',
                          }}
                        >
                          {t('ACTUAL')} :{' '}
                          <span style={{ color: '#C4A61F' }}>
                            {' '}
                            {moment(
                              currentPurchaseOrder.actualDeliveryDate,
                            ).format('DD-MM-YYYY')}{' '}
                            |{' '}
                            {
                              currentPurchaseOrder.baseDeliverySlot.deliverySlot
                                .startTime
                            }{' '}
                            -{' '}
                            {
                              currentPurchaseOrder.baseDeliverySlot.deliverySlot
                                .endTime
                            }{' '}
                          </span>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </>
            )}
            <Row className="mt-3">
              <Col md={8} xs={12}>
                {currentPurchaseOrder.statusId != 1 && (
                  <div
                    style={
                      isMobile
                        ? { textAlign: 'center', color: '#0067D6' }
                        : { color: '#0067D6' }
                    }
                    className="mb-2"
                  >
                    Recommended Allocation
                  </div>
                )}
                <div className="info-container">
                  <div
                    className="purchase-product-info"
                    style={{ backgroundColor: '#E6F4FF' }}
                  >
                    {currentPurchaseOrder.statusId != 1 && (
                      <>
                        <div
                          className={isMobile ? 'text-center' : ''}
                          style={{ color: '#0067D6' }}
                        >
                          {currentPurchaseOrder.baseMarketName && (
                            <strong>
                              {currentPurchaseOrder.baseMarketName}
                            </strong>
                          )}
                        </div>
                        <div
                          className={isMobile ? 'text-center' : ''}
                          style={{ color: '#3D4394' }}
                        >
                          {currentPurchaseOrder.supplierCompanyName && (
                            <>
                              {currentPurchaseOrder.supplierCompanyName}
                              <br />
                            </>
                          )}
                          {t('TOTAL_VALUE')} (LKR):{' '}
                          {commafy(RoundNum(currentPurchaseOrder.total, 2))}
                        </div>
                      </>
                    )}
                    {currentPurchaseOrder.statusId == 1 && (
                      <div
                        style={
                          isMobile
                            ? { textAlign: 'center', color: '#3D4394' }
                            : { color: '#3D4394' }
                        }
                        className="my-3"
                      >
                        {!isMobile ? (
                          <>
                            {' '}
                            Further optimization is required to obtain the best
                            deal for you, please submit your order and we will
                            handle the rest.
                          </>
                        ) : (
                          <>
                            Further optimization is required. Please submit your
                            order.
                          </>
                        )}
                      </div>
                    )}
                  </div>

                  <div>
                    <div className="tableFixHead">
                      <Table>
                        {!isMobile && (
                          <thead>
                            <tr>
                              <th style={{ width: '65%' }}>
                                {t('PRODUCT_DESCRIPTION')}
                              </th>
                              <th> {t('TOTAL_PRICE')} (LKR)</th>
                            </tr>
                          </thead>
                        )}
                        <tbody>
                          {currentPurchaseOrder.products.map(
                            (product, productIndex) => {
                              return (
                                <tr key={product.id + ' ' + productIndex}>
                                  {!isMobile ? (
                                    <>
                                      <td>
                                        <div className="d-flex justify-content-between">
                                          <div>
                                            {product.productName}
                                            <span className="weight">
                                              {currentPurchaseOrder.statusId ==
                                              1 ? (
                                                <>
                                                  <br />
                                                  Q: {product.quantity}{' '}
                                                  {product.unitTypeName}
                                                </>
                                              ) : (
                                                <>
                                                  {product.quantity}{' '}
                                                  {product.unitTypeName}
                                                </>
                                              )}
                                            </span>{' '}
                                            <br />
                                            {product.unitPrice &&
                                              currentPurchaseOrder.statusId !=
                                                1 && (
                                                <>
                                                  LKR{' '}
                                                  {commafy(
                                                    RoundNum(
                                                      product.unitPrice,
                                                      2,
                                                    ),
                                                  )}
                                                  /{product.unitTypeName}
                                                </>
                                              )}
                                          </div>
                                          {currentPurchaseOrder.statusId ==
                                            1 && (
                                            <div className="align-self-end">
                                              {product.unitPrice
                                                ? `Avg. P ${commafy(
                                                    RoundNum(
                                                      product.unitPrice,
                                                      2,
                                                    ),
                                                  )}`
                                                : 'Pending'}
                                            </div>
                                          )}
                                        </div>
                                      </td>
                                      <td className="price">
                                        {product.totalPrice
                                          ? commafy(
                                              RoundNum(product.totalPrice, 2),
                                            )
                                          : 'Pending'}
                                      </td>
                                    </>
                                  ) : (
                                    <td className="d-flex flex-column">
                                      <div style={{ width: '100%' }}>
                                        {product.productName}
                                      </div>

                                      <div className="d-flex flex-row justify-content-between pt-2 ">
                                        <div
                                          className="weight ms-0 "
                                          style={{
                                            width: '25%',
                                            paddingRight: '5px',
                                          }}
                                        >
                                          {product.quantity}
                                          {product.unitTypeName}
                                        </div>
                                        {currentPurchaseOrder.statusId == 1 && (
                                          <div
                                            className="price-avg text-center"
                                            style={{ width: '50%' }}
                                          >
                                            {product.unitPrice ? (
                                              <span>
                                                Avg. P:{' '}
                                                {commafy(
                                                  RoundNum(
                                                    product.unitPrice,
                                                    2,
                                                  ),
                                                )}
                                                /{product.unitTypeName}
                                              </span>
                                            ) : (
                                              <span>Pending</span>
                                            )}
                                          </div>
                                        )}

                                        {product.unitPrice &&
                                          currentPurchaseOrder.statusId !=
                                            1 && (
                                            <div
                                              className="price-avg text-right"
                                              style={{ width: '25%' }}
                                            >
                                              {commafy(
                                                RoundNum(product.unitPrice, 2),
                                              )}
                                              /{product.unitTypeName}
                                            </div>
                                          )}

                                        <div
                                          className="price text-right"
                                          style={{ width: '25%' }}
                                        >
                                          {product.totalPrice
                                            ? commafy(
                                                RoundNum(product.totalPrice, 2),
                                              )
                                            : 'Pending'}
                                        </div>
                                      </div>
                                    </td>
                                  )}
                                </tr>
                              );
                            },
                          )}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </div>
              </Col>
              <Col md={4} xs={12}>
                {currentPurchaseOrder.statusId != 1 && (
                  <div className="order-summery mt-3 mt-md-0">
                    <div className="order-summery-label mb-2">
                      {t('ORDER_SUMMERY')}
                    </div>
                    <Table borderless>
                      <tbody>
                        <tr>
                          <td>{t('SUB_TOTAL')}</td>
                          <td>
                            {commafy(
                              RoundNum(currentPurchaseOrder.subTotal, 2),
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td>{t('SERVICE_FEE')}</td>
                          <td>
                            {commafy(
                              RoundNum(currentPurchaseOrder.serviceFee, 2),
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td>{t('LOADING_FEE')}</td>
                          <td>
                            {commafy(
                              RoundNum(currentPurchaseOrder.loadingFee, 2),
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td>{t('LOGISTIC_FEE')}</td>
                          <td>
                            {commafy(
                              RoundNum(currentPurchaseOrder.logisticFee, 2),
                            )}
                          </td>
                        </tr>
                        {currentPurchaseOrder.interestFee && (
                          <tr>
                            <td>{t('INTEREST_FEE')}</td>
                            <td></td>
                          </tr>
                        )}
                        <tr>
                          <td>{t('TOTAL_BILL')}</td>
                          <td>
                            {commafy(RoundNum(currentPurchaseOrder.total, 2))}
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                )}
                <div
                  className={
                    currentPurchaseOrder.statusId != 1
                      ? ''
                      : 'd-flex flex-column justify-content-center h-100'
                  }
                >
                  <Button
                    color="primary"
                    type="submit"
                    className="form-submit mt-3 w-100"
                    onClick={() => {
                      onSubmitOrder();
                    }}
                  >
                    {t('SUBMIT_ORDER')}
                  </Button>
                  {orderPopup()}
                  <Button
                    outline
                    color="primary"
                    type="submit"
                    className="form-submit mt-3 w-100"
                    onClick={() => {
                      setIsSubmitOrder(false);
                      setIsOrderPopup(prevProps => !prevProps);
                    }}
                  >
                    {t('CANCEL')}
                  </Button>
                </div>
              </Col>
            </Row>
          </>
        )}
      </div>
    </InnerBody>
  );
}
export default Recommendation;
